import { TrackingService } from '../../../service/tracking/trackingService';
import clsx from 'clsx';
import NextImage from '../../NextImage';
import { Text as Paragraph } from '../../Typography';
import { ISocialMedia } from '../types';

interface SocialMediaLinkType {
  socialMedia?: ISocialMedia[];
}

export const SocialMediaLinks = ({ socialMedia }: SocialMediaLinkType) => {
  if (!socialMedia) {
    return null;
  }
  return socialMedia?.map((item, index: number) => (
    <a
      className="flex items-center text-darkgray mb-4"
      href={item.url}
      target="_blank"
      key={item.url ?? `social-${index}`}
      onClick={() => TrackingService.followUsClick}
    >
      {item?.assetsComponents &&
        item?.assetsComponents[0]?.asset &&
        item?.assetsComponents[0]?.asset[0]?.url && (
          <div
            className={clsx(
              'mr-4',
              socialMedia?.length > 3
                ? 'w-5 h-5 md:w-10 md:h-10 lg:w-5 lg:h-5'
                : 'w-30 h-30 md:w-10 md:h-10',
            )}
          >
            <NextImage
              src={item?.assetsComponents[0]?.asset[0]?.url}
              mimeType={item?.assetsComponents[0]?.asset[0]?.mimeType}
              alt=""
              width={18}
              height={18}
            />
          </div>
        )}
      <Paragraph
        tag="div"
        type="md"
        data-ec="follow us"
        data-ea="click"
        data-el={item?.name}
        data-event_name="click_social_follow"
        data-social_platform={item?.name}
      >
        {item?.name}
      </Paragraph>
    </a>
  ));
};
